#phone-number-container {
  width: 100%;

  .phone-number-input {
    display: flex;
    align-items: center;
    background: #ffffff;
    border: 1px solid #222222;
    border-radius: 4px;
    padding-right: 20px;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: black !important;

    .dial-code-block {
      cursor: pointer;

      .dropdown {
        height: 48px;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;

        .dropdown-menu {
          height: 200px;
          overflow: auto;
        }

        button {
          height: 100%;
          background-color: #ffffff;
          border: unset;
          outline: none;
          padding-left: 20px !important;
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          padding-right: 0px !important;

          &:focus-visible {
            outline: none;
            box-shadow: unset;
          }
        }

        .dropdown-toggle::after {
          display: none;
        }
      }

      .image-block-flag {
        display: flex;
        align-items: center;
        height: 25px;
        cursor: pointer;
        width: 60px;
        padding: 0px 16px 0px 16px;
      }
    }

    .saprator-block {
      border-left: 1px solid #b7b7b7;
      margin: 0px 8px 0px 10px;
      height: 26px;
    }

    .number-input-block {
      flex-grow: 1;

      input {
        border: unset;
        outline: unset;
        width: 100%;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
}

// #phone-number-container {
//   width: 100%;

//   .react-tel-input .form-control {
//     width: 100%;
//     height: 54px;
//     background: #ffffff;
//     border: 1px solid #222222;
//     border-radius: 4px;
//     padding-left: 55px;
//     padding-right: 10px;
//     font-weight: 500;
//     font-size: 14px;
//     line-height: 21px;
//     color: black !important;
//     white-space: nowrap;
//     overflow: hidden;
//     text-overflow: ellipsis;
//   }

//   ::placeholder {
//     color: #b7b7b7;
//     opacity: 1;
//     /* Firefox */
//   }

//   :-ms-input-placeholder {
//     color: #b7b7b7;
//   }

//   ::-ms-input-placeholder {
//     color: #b7b7b7;
//   }

//   .react-tel-input .flag-dropdown {
//     border: 1px solid #222222;
//     background: #ffffff;
//   }

//   .react-tel-input .selected-flag {
//     width: 45px;
//     padding: 0 12px 0 12px;
//   }

//   .form-control:focus {
//     outline: 1px solid;
//     box-shadow: unset;
//   }

//   .country-list {
//     .country {
//       text-align: left;
//     }
//   }
// }