// #round-check-box-container {

//     // .form-check:last-child {
//     //     margin-bottom: 10px;
//     // }
//     .round-form-check {
//         position: relative;
//     }

//     .round-form-check-input {
//         border: 1px solid #718095;
//         border-radius: 50% !important;
//         width: 16px;
//         height: 16px;
//         margin-top: 10px;

//         &:focus {
//             box-shadow: unset;
//         }
//     }


//     // .round-form-check-input:checked {
//     //     background-color: #1571ED;
//     // }
// }

.round {
    position: relative;
    margin-top: 5px;

}



.round label {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 50%;
    cursor: pointer;
    height: 20px;
    left: 0;
    position: absolute;
    top: 0;
    width: 20px;
}

.round label:after {
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    content: "";
    height: 6px;
    left: 3px;
    opacity: 0;
    position: absolute;
    top: 5px;
    transform: rotate(-45deg);
    width: 12px;
}

.round input[type="checkbox"] {
    visibility: hidden;
}

.round input[type="checkbox"]:checked+label {
    background-color: #1571ED;
    // border-color: red;
}

.round input[type="checkbox"]:checked+label:after {
    opacity: 1;
}