#text-editor-container {
  .DraftEditor-editorContainer {
    z-index: unset;
  }
  .rdw-editor-wrapper {
    border: 1px solid #414141;
  }
  .rdw-editor-toolbar {
    border: unset;
    border-bottom: 1px solid #f1f1f1;
    padding: 5px 5px 0px 5px;
  }
  .public-DraftStyleDefault-block {
    margin: 0.5em 0 0 0;
  }
  .public-DraftEditor-content {
    padding: 0px 5px 10px 16px;
  }
  .public-DraftEditorPlaceholder-inner {
    padding-left: 20px;
  }
}
