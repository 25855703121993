#modal-container {
  position: relative;
  border-radius: 6px;
  padding-top: 44px;
  .modal-close-button {
    position: absolute;
    right: 18px;
    top: 10px;
    font-size: 20px;
    color: #a9a9a9;
  }
}
.modal-block-custom {
  padding-right: unset !important;
}
