#bar-chart-container {
    .bar-chart-card-block {
        background: #FFFFFF;
        border: 1px solid #E7E7E7;
        border-radius: 5px;
    }


    @media only screen and (max-width: 768px) {}

    @media only screen and (min-width: 768px) and (max-width: 992px) {}

    @media only screen and (min-width: 992px) and (max-width: 1200px) {}
}