#add-brand-container {
    .image-upload-section {
        .upload-banner-container {
            background: #EEEFF0;
            border-radius: 10px;
            border: 1px dashed #718095;
            position: relative;
            height: 40px;
            display: flex;
            align-items: center;

            .banner-upload {
                .fileType[type="file"] {
                    -webkit-appearance: none;
                    position: absolute;
                    top: 0;
                    left: 0;
                    opacity: 0;
                    width: 100%;
                    height: 100%;
                }
            }

            .logo-upload {
                position: absolute;
                width: 75px;
                height: 75px;
                left: 40px;
                top: 130px;
                background: #EEEFF0;
                border: 1px dashed #718095;
                border-radius: 50%;

                .logoType[type="file"] {
                    -webkit-appearance: none;
                    position: absolute;
                    top: 0;
                    left: 0;
                    opacity: 0;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}