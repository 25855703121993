#user-journey-container {
    .heading {
        max-width: 475px;
        padding: 12px 16px;
        border-radius: 10px;
        background-color: #eeeff0;
    }


}

#user-detail-container {
    .profile-image {
        max-width: 60px;
        max-height: 60px;
        border: 1px solid var(--black-20);
        border-radius: 5px;

        img {
            height: 100%;
            width: 100%;
            object-fit: contain;
        }
    }

    .for-border {
        border-right: 1px solid var(--black-20);
        padding-left: 20px;

    }

    .detail-block {
        .block-one {
            height: 100%;
            display: flex;
            flex-direction: column;
            border: 1px solid var(--black-20);
            border-radius: 10px;
        }
    }
}