#approval-details {
    display: flex;
    justify-content: center;

    .details-container {
        width: 100%;

        .details-content-container {
            border: 1px solid #E7E7E7;
            border-radius: 8px;

            .tattoo-container {
                height: 265px;
                background-color: #E7E7E7;
                border-radius: 8px;

                .logo-image {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                    border-radius: 8px
                }

                .no-logo {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 265px;
                    border-radius: 8px
                }

            }

            .sub-tattoo-container {
                max-width: 100px;
                height: 80px;

                .sub-tattoo-images {
                    width: 100%;
                    height: 100%;
                    min-width: 100px;
                    max-height: 80px;
                    border-radius: 5px;
                    object-fit: cover;
                }
            }

            .beauty-places-container {


                .address {
                    width: 70%;
                }

                .plan-info {
                    border-radius: 20px;
                    align-items: center;
                    padding: 5px 10px;
                }
            }

            .bottom-bdr {
                border-bottom: 1px solid #a0a0a0;
            }

            .license-container {
                .license-image-container {
                    max-width: 89px;
                    height: 63px;
                    border: 0.5px solid #CFCFCF;
                    padding: 5px;
                    background-color: #E7E7E7;
                }

                // .license-image-container img {
                //     width: 100%;
                //     height: 100%;
                //     object-fit: cover;
                // }
            }
        }

        .button-container {
            background: #FFFFFF;
            box-shadow: 0px -4px 30px rgba(0, 0, 0, 0.1);
        }
    }

}