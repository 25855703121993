#password-input-container {
  width: 100%;

  .password-input-block {
    position: relative;

    .password-input {
      width: -webkit-fill-available;
      height: 54px;
      background: #ffffff;
      border: 1px solid #414141;
      border-radius: 5px;
      padding-left: 20px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      color: black !important;
    }

    ::placeholder {
      color: #b7b7b7;
      opacity: 1;
      /* Firefox */
    }

    :-ms-input-placeholder {
      color: #b7b7b7;
    }

    ::-ms-input-placeholder {
      color: #b7b7b7;
    }

    .eye-container {
      position: absolute;
      top: 18px;
      right: 26px;
      cursor: pointer;
      display: flex;
      align-items: center;
    }
  }
}
