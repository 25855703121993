#add-product-container {
    .upload-product-container {
        background: #EEEFF0;
        border-radius: 10px;
        border: 1px dashed #718095;
        position: relative;
        height: 250px;
        display: flex;
        align-items: center;

        .product-upload {
            .fileType[type="file"] {
                -webkit-appearance: none;
                position: absolute;
                top: 0;
                left: 0;
                opacity: 0;
                width: 100%;
                height: 100%;
            }
        }
    }

    .features-list ul {
        margin-bottom: unset;
        padding-left: unset;
    }

    .product-list-container {
        .product-list {
            .products-items {
                background: #FFFFFF;
                border: 1px solid #E7E7E7;
                border-radius: 10px;

            }
        }
    }

}

#product-list-review-container-3 {
    border-radius: 10px;

    .banner-logo-container {
        height: 460px;
        position: relative;

        .brand-banner {
            position: absolute;
            height: 422px;

            .review-banner-image {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 5px;
                background-repeat: no-repeat;
                background-position: bottom center;
                background-size: cover;
                overflow: hidden;
            }
        }

        .brand-logo {
            width: 80px;
            height: 80px;
            border-radius: 50%;
            // background-color: lightgreen;
            border: 1px solid #718095;
            position: absolute;
            left: 40px;
            bottom: 0;

            .review-logo-image {
                width: 100%;
                height: 100%;
                border-radius: 50%;
                object-fit: cover;
                background-repeat: no-repeat;
                background-position: bottom center;
                background-size: cover;
                overflow: hidden;
            }
        }
    }
}