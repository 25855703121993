#switch-box-container {
  .form-check-input {
    // border: 1px solid #718095;
    // border-radius: 2px;
    width: 45px;
    height: 20px;
    // margin-top: 0px;
    // margin-right: 16px;
    // cursor: pointer;
    &:focus {
      box-shadow: unset;
      border: 1px solid rgba(0, 0, 0, 0.25);
      background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%280, 0, 0, 0.25%29%27/%3e%3c/svg%3e");
    }
    &:checked {
      background-color: #1ba54a;
      border-color: #1ba54a;
      background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e");
    }
  }
}
