#text-area-container {
  width: 100%;

  .text-input-area {
    width: 100%;
    border: 1px solid #707070;
    border-radius: 5px;
    padding: 20px;
    padding-top: 10px;
    resize: none;
  }
}