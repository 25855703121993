#add-beauticians-register-container {
    #RFS-StepperContainer {

        // .StepButton-0-2-4 {
        //     // background-color: #132c4e7a;
        //     background-color: #132c4e;
        // }
        .Label-0-2-9 {
            font-size: 15px;
            font-weight: 500;
            line-height: 27px;
        }
    }

    .steps-form-container {
        border-radius: 5px;
        box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.07);
    }
}