#user-profile-container {
  margin: 24px;
  .card-effect {
    background-color: #fff;
    border-radius: 10px;
  }
  .change-profile-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px 0px !important;
    .change-profile-block {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 150px;
      width: 200px;
      background-image: url("https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8dXNlcnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60");
      background-repeat: no-repeat;
      object-fit: cover;
      background-repeat: no-repeat;
      background-position: top center;
      background-size: cover;
      overflow: hidden;
      border-radius: 10px;
      .camera-icon-block {
        height: 45px;
        width: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        backdrop-filter: blur(10px);
        border-radius: 50%;
        cursor: pointer;
      }
    }
    .text-block {
      font-size: 15px;
      font-weight: 500;
      line-height: 27px;
      color: #2563eb;
      cursor: pointer;
    }
  }
  .change-password-block {
  }
}
