#pie-chart-container {


    .pieChart-block {
        border: 1px solid #E7E7E7;
        background: #FFFFFF;

        .chart-block {
            display: flex;
            flex-direction: column;
        }
    }

    .piechart-desc-block {
        flex-grow: 1;
        border: 2px solid #E7E7E7;
    }


    @media only screen and (max-width: 768px) {}

    @media only screen and (min-width: 768px) and (max-width: 992px) {}

    @media only screen and (min-width: 992px) and (max-width: 1200px) {}
}