.approval-container {
    .heading-section {
        .desc-block {
            width: 32%;
            border: 1px solid #E2E5E8;
            border-radius: 10px;
            background-color: #EEEFF0;
        }
    }

}