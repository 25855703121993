#cardlist-container {
    .number-of-downloads-card {
        border: 0.5px solid #CFCFCF;
        border-radius: 5px;
    }

    .main-card-block {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 24px;

        .card-block {
            background: #FFFFFF;
            border: 1px solid #E7E7E7;
            border-radius: 5px;

            .card-block-heading {
                border-bottom: 1px solid #CFCFCF;
                margin-bottom: 19px;
            }


            .total-list-content {
                border-top: 1px solid #dee2e6;
                padding-top: 10px;
                margin-top: 10px;
            }

        }
    }

    @media only screen and (max-width: 768px) {
        .main-card-block {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            grid-gap: 24px;
        }
    }

    @media only screen and (min-width: 768px) and (max-width: 992px) {
        .main-card-block {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 24px;
        }
    }

    @media only screen and (min-width: 992px) and (max-width: 1200px) {}

}