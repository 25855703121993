#step-three-container {
    .upload-banner-container {
        background: #EEEFF0;
        border-radius: 10px;
        border: 1px dashed #718095;
        position: relative;
        height: 50px;
        display: flex;
        align-items: center;

        .banner-upload {
            .fileType[type="file"] {
                -webkit-appearance: none;
                position: absolute;
                top: 0;
                left: 0;
                opacity: 0;
                width: 100%;
                height: 100%;
            }
        }
    }


}