@import "./color.scss";

* {
  margin: 0;
  padding: 0;
  font-family: "League Spartan";
  letter-spacing: 0.015em;
}

html {
  scroll-behavior: smooth;
}

.letter-space-15 {
  letter-spacing: 0.01em;
}

@mixin text-font-style($f-size, $f-weight, $line-height) {
  font-size: $f-size !important;
  font-weight: $f-weight !important;
  line-height: $line-height !important;
}

.text-9-500-15 {
  @include text-font-style(9px, 500, 15px);
}

.text-9-500 {
  @include text-font-style(9px, 500, 16px);
}

.text-13-500-21 {
  @include text-font-style(13px, 500, 21px);
}

.text-13-500 {
  @include text-font-style(13px, 500, 27px);
}

.text-13-600 {
  @include text-font-style(13px, 600, 23px);
}

.text-11-500-18 {
  @include text-font-style(11px, 500, 18px);
}

.text-11-500 {
  @include text-font-style(11px, 500, 20px);
}

.text-15-500-25 {
  @include text-font-style(15px, 500, 25px);
}

.text-15-500 {
  @include text-font-style(15px, 500, 27px);
}

.text-15-600 {
  @include text-font-style(15px, 600, 27px);
}

.text-15-700 {
  @include text-font-style(15px, 700, 27px);
}

.text-16-500 {
  @include text-font-style(16px, 500, 24px);
}

.text-16-600 {
  @include text-font-style(16px, 600, 23px);
}

.text-17-500 {
  @include text-font-style(17px, 500, 31px);
}

.text-17-600 {
  @include text-font-style(17px, 600, 31px);
}

.text-17-700 {
  @include text-font-style(17px, 700, 30px);
}

.text-17-700-31 {
  @include text-font-style(17px, 700, 31px);
}

.text-17-700 {
  @include text-font-style(17px, 700, 31px);
}

.text-20-700 {
  @include text-font-style(20px, 700, 34px);
}

.text-24-700 {
  @include text-font-style(24px, 700, 41px);
}

.text-32-700 {
  @include text-font-style(32px, 700, 54px);
}

$sizes: 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 20, 21, 22, 23,
  24, 25, 26, 27, 28, 30, 32, 33, 35, 39, 40, 42, 44, 45, 48, 49, 50, 55, 56, 57, 58, 60, 64,
  65, 67, 68, 69, 70, 80, 85, 88, 90, 94, 96, 98, 99, 100, 103, 124, 130, 135, 150, 196, 200, 205,
  409;

@each $size in $sizes {
  .cps-#{$size} {
    padding-left: #{$size}px !important;
  }

  .cpe-#{$size} {
    padding-right: #{$size}px !important;
  }

  .cpt-#{$size} {
    padding-top: #{$size}px !important;
  }

  .cpb-#{$size} {
    padding-bottom: #{$size}px !important;
  }

  .cms-#{$size} {
    margin-left: #{$size}px !important;
  }

  .cme-#{$size} {
    margin-right: #{$size}px !important;
  }

  .cmt-#{$size} {
    margin-top: #{$size}px !important;
  }

  .cmb-#{$size} {
    margin-bottom: #{$size}px !important;
  }

  .h-#{$size} {
    height: #{$size}px !important;
  }
}

.pointer {
  cursor: pointer;
}

.card-effect {
  background-color: #fff;
  border-radius: 10px;
  // border: 1px solid #E7E7E7;
}

.modal-90w {
  width: 80% !important;
  max-width: none !important;
}

.modal-409 {
  width: 409px !important;
  max-width: none !important;
}

.modal-567 {
  width: 567px;
  max-width: none !important;
}

.btn-check:checked+.btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check)+.btn:active {
  border: unset;
  border-color: unset;
}

.dropdown-toggle::after {
  display: none !important;
}

.btn {
  padding: unset !important;
  --bs-btn-border-width: unset !important;
}

.fill-image {
  // width: 95%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: cover;
  overflow: hidden;
}



.beautician-success-conteiner {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 150px;
  right: 0;
  top: 75px;
  text-align: center;
  width: fit-content;
  background-color: #1571ed;
  padding: 10px 20px;

  i {
    color: #1571ed;
  }
}

.success-conteiner {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: -20px;
  text-align: center;
  width: fit-content;
  background-color: #219653;
  padding: 10px 20px;

  i {
    color: #219653;
  }
}

.success-conteiner-2 {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 100px;
  text-align: center;
  width: fit-content;
  background-color: #219653;
  padding: 10px 20px;

  i {
    color: #219653;
  }
}

.primary-container {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 0;
  text-align: center;
  width: fit-content;
  background-color: #1571ed;
  padding: 10px 20px;

  i {
    color: #1571ed;
  }
}

.dashboard-success-container {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 50px;
  right: 0;
  top: 100px;
  text-align: center;
  max-width: 350px;
  height: 350px;
  background-color: #1571ed;
  display: flex;
  justify-content: center;
  align-items: center;
  // margin: auto;
}

.white-icon {
  filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(147deg) brightness(102%) contrast(111%);
}


.box-shadow {
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.07);
}

.message-container {

  max-width: 505px;
}

.message-card {
  background-color: #eeeff0;
  border: 0.5px #a0a0a0 solid;
  border-radius: 5px;
  padding: 15px;
}

.list-items {
  position: relative;
  display: block;
  padding: 10px;
  text-decoration: none;
  margin-bottom: 5px;
}

.list-title {
  display: flex;
  justify-content: space-between;
}

.list-title span i {
  display: inline-block;
  width: 1.5em;
}

.toggle-btn {
  cursor: pointer;
  transition: transform .3s;
  margin-left: 8px;
}

.open .toggle-btn {
  transform: rotate(180deg);
}

.list-content {
  padding-top: .25em;
  height: 0;
  overflow: hidden;
}

.open .list-content {
  height: auto;
}

.beautician-cardList:last-child {
  border-top: 1px solid #dee2e6;
  padding-top: 10px;
  margin-top: 10px;
}

.header-user-data-checkbox {
  border: 1px solid black !important;
  border-radius: 10px !important;
  width: 15px;
  height: 15px;
  margin-top: 5px;
  margin-right: 5px;
  // padding-top: 20px !important;
  cursor: pointer;

  &:focus {
    box-shadow: unset;
  }
}

.user-data-checkbox {
  border: 1px solid black !important;
  border-radius: 10px !important;
  width: 15px;
  height: 15px;
  margin-top: 10px;
  margin-right: 10px;
  // padding-top: 20px !important;
  cursor: pointer;

  &:focus {
    box-shadow: unset;
  }
}

.popup-shadow {
  box-shadow: 5px 10px #888888;
}

.add-features-list {
  min-height: 558px;
  display: flex;
  flex-direction: column;
}

.add-features-list ul {
  margin-bottom: unset;
  padding-left: unset;
}

.center-text {
  height: 48px;
  display: flex;
  align-items: center;
}