#check-box-container {
  // .form-check {
  //   margin-bottom: 0px;
  //   padding-left: 0px
  // }

  .check-form {
    display: flex;
    align-items: center;
  }

  .check-form:last-child {
    margin-bottom: 10px;
  }

  .form-check-input {
    border: 1px solid #718095;
    border-radius: 2px;
    width: 20px;
    height: 20px;
    margin-top: 0px;
    margin-right: 16px;
    cursor: pointer;

    &:focus {
      box-shadow: unset;
    }
  }

  .form-check-label {
    margin-top: 0px;
  }
}