#change-password-continer {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;


    .change-password-block {
        width: 50%;

        .change-password-heading-block {
            box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.05);
            border-radius: 5px;

            .change-password-logo-block {
                text-align: center;
            }

            .change-password-logo-text {
                text-align: center;
            }
        }

        .change-password-content-block {
            border: 1px solid #F2F2F2;
            box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.05);
            border-radius: 10px;

            .change-password-content-block-heading {
                text-align: center;
            }
        }

    }

    @media only screen and (max-width: 768px) {
        .change-password-block {
            width: 90%;
        }
    }

    @media only screen and (min-width: 768px) and (max-width: 992px) {}

    @media only screen and (min-width: 992px) and (max-width: 1200px) {}
}